import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { auth } from "./init";

export const uploadImageProfile = async (file) => {
    try {
        const user = auth.currentUser;
        const storage = getStorage();
        const storageRef = ref(storage, `profile/${user.uid}/${file.name}`);
        
        // Faz o upload da imagem
        await uploadBytes(storageRef, file);
        
        // Obtém a URL da imagem após o upload
        const url = await getDownloadURL(storageRef);
        
        return url;
    } catch (error) {
        console.error('Erro ao fazer o upload da imagem:', error);
        throw new Error('Falha no upload da imagem');
    }
}

export const deleteImageProfile = async (imgUrl, path) => {
    try {
        const user = auth.currentUser;
        const storage = getStorage();
        const imageRef = ref(storage, `profile/${user.uid}/${path}`);

        await deleteObject(imageRef);
        console.log("Imagem tambem foi removida com sucesso");
    } catch (er) {
        const path = imgUrl.split('?')[0].split('o/')[1];
        console.error(er);
        console.log(path);
    }
}