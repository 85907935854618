import "./comment.css";
import React, { useEffect, useState } from "react";
import { deleteComment, fetchCommentsWithUserProfile } from "../../../../../../../firebase/post";
import Loading from "../../../../../../../components/loading/loading";
import { useAuth } from "../../../../../../../contexts/contexts";
import Image1 from "../../../../../../../assets/logo/Logo.png";
import { MdClose } from "react-icons/md";
import LikeComment from "./likeComment";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../../../firebase/init";

const Comment = ({ authorId, postId, newComment, imgProfile, nameUser }) => {
    const { currentUser } = useAuth();
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(null);
    const [visibleComments, setVisibleComments] = useState(3); // Controla quantos comentários são exibidos

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const commentsData = await fetchCommentsWithUserProfile(authorId, postId);
    
                // Combina os comentários existentes com os novos
                setComments((prev) => [
                    ...prev.filter((comment) => !commentsData.some((c) => c.id === comment.id)),
                    ...commentsData,
                ]);
            } catch (error) {
                console.error("Erro ao carregar comentários:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchComments();
    }, [authorId, postId]);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(db, "users", authorId, "posts", postId, "comments"),
            (snapshot) => {
                const updatedComments = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                // Atualiza o estado preservando os comentários antigos
                setComments((prevComments) => {
                    const newComments = updatedComments.filter(
                        (newComment) =>
                            !prevComments.some(
                                (oldComment) => oldComment.id === newComment.id
                            )
                    );

                    return [...prevComments, ...newComments];
                });
            }
        );
    
        return () => unsubscribe();
    }, [authorId, postId]);

    useEffect(() => {
        if (newComment) {
            setComments((prevComments) => [...prevComments, newComment]);
        }
    }, [newComment]);

    const deleteCommentOnPost = async (authorId, postId, commentId) => {
        const confirm = window.confirm("Você deletará permanentemente seu comentário. Essa ação não poderá ser desfeita.");
        
        if (confirm) {
            try {
                setIsDeleting(true);
                await deleteComment(authorId, postId, commentId);
                setComments((prevComments) => prevComments.filter((comment) => comment.id !== commentId));
            } catch (error) {
                console.error("Erro ao deletar comentário:", error);
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const loadMoreComments = () => {
        setVisibleComments((prev) => prev + 3); // Carrega mais 3 comentários
    };
    
    if (loading) return <Loading />;

    if (error) return <p className="error">{error}</p>;
    
    return comments && comments.length > 0 && (
        <div className="comment__user">
            {comments.slice(0, visibleComments).map((comment) => (
                <div key={comment.id} className="comment__user__container">
                    <div className="comment__user__avatar">
                        <img
                            src={comment.imgProfile || imgProfile}
                            alt={`${comment.nomeProfile}'s avatar`}
                            className="comment__avatar"
                        />
                    </div>
                    <div className="comment-content">
                        <p className={comment.userId === currentUser.uid ? "comment-author" : "comment-user"}>
                            {comment?.nomeProfile || nameUser}
                        </p>
                        <p className="comment-text">{comment.commentText}</p>
                        <button className="comment-reply">
                            <span>Responder</span>
                        </button>
                    </div>
                    <div className="comment-close">
                        {comment.userId === currentUser.uid && (
                            <button
                                onClick={() => deleteCommentOnPost(authorId, postId, comment.id)}
                                className="comment__btn__close"
                                disabled={isDeleting}
                            >
                                <MdClose />
                            </button>
                        )}
                    </div>
                </div>
            ))}
            {comments.length > visibleComments && (
                <button className="load-more" onClick={loadMoreComments}>
                    Ver mais
                </button>
            )}
        </div>
    );
};

export default Comment;
