import "./fetchPost.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { deleteImagePost, deletePost, fetchCollectionPost, updatePost } from "../../../../../../firebase/post";
import { doGetDocumentOnStorage } from "../../../../../../firebase/docs";
import Image1 from "../../../../../../assets/logo/Logo.png";
import { PiChatCircle, PiChatCircleFill, PiShareFat, PiShareFatFill, PiStarFour, PiStarFourFill } from "react-icons/pi";
import { MdClose, MdEdit } from "react-icons/md";
import { useAuth } from "../../../../../../contexts/contexts";
import { FaCheck } from "react-icons/fa";
import { TbShoppingBag } from "react-icons/tb";
import { BiSolidShoppingBag } from "react-icons/bi";

const FetchPost = () => {
    
    const { currentUser } = useAuth();

    const [reaction, setReaction] = useState(false);
    const [edit, setEdit] = useState(null);
    const [allPost, setAllPost] = useState([]);
    const [dataProfile, setDataProfile] = useState({});
    const [formValues, setFormValues] = useState({ title: "", content: "" });
    const [tags, setTags] = useState({});
    const [validate, setValidate] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            const post = await fetchCollectionPost();
            const data = await doGetDocumentOnStorage();
            setAllPost(post);
            setDataProfile(data);
        };
        fetchPost();
    }, []);

    const removeTextRegex = (text) => {
        const regex = /#(\w+)/g;
        return text.replace(regex, "").trim();
    };

    const handleDeletePost = async (e, postId, imgUrl, path) => {
        e.preventDefault();
        const confirm = window.confirm("Essa ação não poderá ser desfeita. Deseja continuar?");
        if (confirm) {
            await deletePost(postId);
            if (imgUrl && path) {
                await deleteImagePost(imgUrl, path);
            }
        } else {
            return;
        }
    }

    const handleRegulateTextarea = (e) => {
        const { id, value } = e.target;
        const textarea = e.target;
        const currentValue = textarea.value;
    
        // Atualiza o campo 'content' no formValues
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [id]: value,
            content: currentValue, 
        }));
    
        // Extração das tags
        const exTags = {};
        const regex = /#(\w+)/g; // Encontra as palavras com #
        let match;
        while ((match = regex.exec(currentValue)) !== null) {
            exTags[match[1]] = true; // Adiciona a tag ao objeto
        }

        // Atualiza o estado de tags
        setTags(exTags); 
        // Ajusta a altura do textarea dinamicamente
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleEdit = (e, post) => {
        e.preventDefault();
    
        setEdit((prev) => (prev === post.id ? null : post.id)); // Alterna entre editar ou não o post atual
    
        if (edit !== post.id) {
            setFormValues({
                title: post.title || "",
                content: post.content || "",
            });
            setTags(post.tags || {});
        }
    
        setValidate(false);
    };
    
    

    const handleSubmit = async (e, postId) => {
        e.preventDefault();
    
        // Verifique se os campos estão preenchidos
        if (!formValues?.title?.trim() || !formValues?.content?.trim()) {
            console.error("Os Campos não podem estar vazios.");
            alert("Preencha todos os campos antes de salvar.");
            return;
        }
    
        const dataForm = {
            title: formValues.title.trim(),
            content: formValues.content.trim(),
            tags: { ...tags },
        };
    
        try {
            await updatePost(postId, dataForm);
            setEdit(false); // Fecha o modo de edição após o sucesso
            setValidate(false);
        } catch (er) {
            console.error("Erro ao atualizar a postagem:", er.message);
        } finally {
            window.location.reload();
        }
    };    

    return (
        <>
            {allPost.map((post) => (
                <motion.div
                    className="post myPost"
                    key={post.id}
                    initial={{scale: 0}}
                    animate={{scale: 1}}
                    exit={{scale: 0}}>
                        <fieldset className="myPost__content">
                            <div className="avatar">
                                <div className="avatar-profile">
                                    <div className="avatar-image">
                                        <img alt="Imagem de Perfil" src={post?.imgProfile || Image1}/>
                                    </div>
                                    <div className="avatar-info">
                                        <p>{dataProfile.nomeEmpresa}</p>
                                    </div>
                                </div>
                                {currentUser.uid === post.authorId && 
                                    <div className="avatar-closed">
                                        <button onClick={(e) => handleDeletePost(e, post.id, post.imgUrl, post.nameImgUrl)} className="avatar-mdClose">
                                            <MdClose />
                                        </button>
                                    </div>
                                }
                            </div>
                            {post.imgUrl &&
                                <div className="post__content__image">
                                    <img alt={post.nameImgUrl} src={post.imgUrl}/>
                                </div>
                            }
                            
                            <div className="content">
                                <div className="reaction">
                                    <button className="reaction__btn star" disabled={!reaction}>{!reaction ? <PiStarFour /> : <PiStarFourFill />}</button>
                                    <button className="reaction__btn star" disabled={!reaction}>{!reaction ? <PiChatCircle /> : <PiChatCircleFill />}</button>
                                    <button className="reaction__btn star" disabled={!reaction}><BiSolidShoppingBag /></button>
                                    
                                    {currentUser.uid === post.authorId &&
                                        <button onClick={(e) => handleEdit(e, post)} className="reaction__btn star"> 
                                            {edit === post.id ? <MdClose /> : <MdEdit />}
                                        </button>
                                    }

                                    {edit === post.id && currentUser.uid === post.authorId && 
                                        <button 
                                            className="post__btnSend" 
                                            onClick={(e) => handleSubmit(e, post.id)}> 
                                            <FaCheck /> 
                                        </button>
                                    }

                                </div>

                                {edit !== post.id &&
                                    <>
                                        <p><b>{post.title}</b></p>
                                        <p>{removeTextRegex(post.content)}</p>
                                    </>
                                }
                                
                                {edit === post.id && 
                                    <motion.div
                                        className="post__edit"
                                        initial={{scale: 0}}
                                        animate={{scale: 1}}
                                        exit={{scale: 0}}>

                                        <input 
                                            className="post__title"
                                            value={formValues.title}
                                            onChange={(e) => setFormValues(prev => ({...prev, title: e.target.value}))}
                                            placeholder="Qual será o titulo?" />
                                        <textarea 
                                            className="post__text"
                                            value={formValues.content}
                                            placeholder="Já fez sua postagem da semana?" 
                                            onChange={handleRegulateTextarea} />
                                        
                                    </motion.div>
                                }

                            </div>
                            <div className="tags">
                                {Object.keys(post.tags).map((tag) => (
                                    <p key={tag}>#{tag}</p>
                                ))}
                            </div>
                        </fieldset>
                    </motion.div>
            ))}
        </>
    );
}

export default FetchPost;