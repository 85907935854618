import "./mobile_config.css";
import "./homePage.css";
import Post from "../components/cards/card-post/post";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa";
import { doGetDocumentOnStorage, doUpdateForm } from "../../../../firebase/docs";
import { MdClose } from "react-icons/md";
import FetchAllPost from "../components/cards/card-post/fetchAllPost";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
    const [filter, setFilter] = useState({ tag: "" });
    const [tags, setTags] = useState({});
    const [dataFilter, setDataFilter] = useState([]);

    useEffect(() => {
        const fetchDataFilter = async () => {
            const data = await doGetDocumentOnStorage();

            // Garante que os filtros armazenados sejam um array de strings
            setDataFilter(Object.keys(data.filter || {}));
        };
        fetchDataFilter();
    }, []);

    const handleSubmitFilter = async () => {
        const dataForm = {
            filter: tags,
        };
        try {
            await doUpdateForm(dataForm);

            // Atualiza o filtro local
            setDataFilter(Object.keys(tags));
        } catch (er) {
            console.error("Erro:", er.code);
        }
    };

    const clearFilter = async () => {
        const dataForm = { filter: {} };
        try {
            const confirm = window.confirm("Você limpará seus filtros (#).");
            if (confirm) {
                await doUpdateForm(dataForm);

                // Limpa os filtros localmente
                setDataFilter([]);
                setFilter({ tag: "" });
                setTags({});
            }
        } catch (er) {
            console.error("Erro:", er.code);
        }
    };

    const handleRegulateTextarea = (e) => {
        const { value } = e.target;
        const textarea = e.target;

        const regex = /^(#\w*\s?)*$/;

        if (!regex.test(value)) {
            return; // Sai da função sem atualizar nada
        }

        setFilter((prevFilter) => ({
            ...prevFilter,
            tag: value,
        }));

        const exTags = {};
        const tagRegex = /#(\w+)/g;
        let match;
        while ((match = tagRegex.exec(value)) !== null) {
            exTags[match[1]] = true;
        }

        setTags(exTags);

        textarea.style.height = "49px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    return (
        <div className="homePage">
            <Helmet>
                <title>Página Inicial - Desenholy</title>
            </Helmet>
            <div className="homePage__left"></div>
            <div className="homePage__middle">
                <div className="homePage__dv">
                    <fieldset className="form__section homePage__dv__textarea">
                        <legend>Filtrar</legend>
                        <div className="homePage__filter__tags">
                            <textarea
                                value={filter.tag}
                                onChange={handleRegulateTextarea}
                                className="filter__perTags"
                                placeholder="Insira os filtros colocando #"
                            />
                            {Object.keys(tags).length > 0 ? (
                                <motion.button
                                    onClick={handleSubmitFilter}
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    className="homePage__filter__faCheck"
                                >
                                    <FaCheck />
                                </motion.button>
                            ) : (
                                <motion.button
                                    onClick={clearFilter}
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    className="homePage__filter__mdClose"
                                >
                                    <MdClose />
                                </motion.button>
                            )}
                        </div>
                        <motion.div
                            className="homePage__tags"
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                        >
                            {tags && Object.keys(tags).length < 1 ? (
                                <>
                                    {dataFilter.length < 1 ? (
                                        <i className="title__tags">Nenhum filtro</i>
                                    ) : (
                                        <i className="title__tags">Filtro:</i>
                                    )}
                                    {dataFilter.map((filter) => (
                                        <span key={filter} className="tag">
                                            #{filter}
                                        </span>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <i className="title__tags">Novo filtro:</i>
                                    {Object.keys(tags).map((tag) => (
                                        <span key={tag} className="tag">
                                            #{tag}
                                        </span>
                                    ))}
                                </>
                            )}
                        </motion.div>
                    </fieldset>
                </div>
                <div className="homePage__dv">
                    <div className="home__middle">
                        <Post />
                        <FetchAllPost dataFilter={dataFilter} />
                    </div>
                </div>
            </div>
            <div className="homePage__right"></div>
        </div>
    );
};

export default HomePage;
