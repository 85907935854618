import React, { useState, useEffect } from "react";
import "./form.css";
import { Helmet } from "react-helmet-async";
import { doGetDocumentOnStorage } from "../../../../firebase/docs";
import { useAuth } from "../../../../contexts/contexts";
import Profile from "../components/cards/card-profile/profile";
import "jquery-mask-plugin";
import Infos from "../components/profile/infos";
import Post from "../components/cards/card-post/post";
import FetchPost from "../components/cards/card-post/fetchPost";

const FormInfos = () => {
    const { currentUser } = useAuth();
    
    const [formValues, setFormValues] = useState({});
    const [editInput, setEditInput] = useState(false);

    useEffect(() => {
        const fetchForm = async () => {
            const data = await doGetDocumentOnStorage();
            try {
                if (currentUser) {
                    setFormValues((prev) => ({
                        ...prev,
                        ...data
                    }));
                }
            } catch (error) {
                console.log("Houve um erro no formulário do perfil.");   
            } finally {
            }
        };
        fetchForm();
    }, [currentUser]);

    const handleEditInput = (e) => {
        e.preventDefault();
        setEditInput((prev) => !prev);
    }


    return (
        <form className="form">
            <Helmet>
                <title>
                    {`Desenholy - Perfil`}
                </title>
            </Helmet>

            <div className="title__ui">
                <p className="title__ui__text">
                    Preencha as informações corretamente para que seu perfil performe cada vez melhor.
                </p>
            </div>

            {/* Preview do card-perfil */}
            <fieldset className="form__section">
                <Profile 
                    imgProfile={formValues.imgProfile}
                    nameImgProfile={formValues.nameImgProfile}
                    nomeEmpresa={formValues.nomeEmpresa}
                    tipoEmpresa={formValues.tipoEmpresa}
                    website={formValues.website}
                    emailContato={formValues.emailContato}
                    obs={formValues.observacoesAdicionais}
                    func={formValues.horarioFuncionamento}
                    edit={handleEditInput}
                    imageFoc={editInput}
                    activated={handleEditInput} />
            </fieldset>

            {editInput && 
                <Infos 
                    handleEditInput={handleEditInput}/>
            }

            {!editInput &&
                <>
                    <Post />
                    <FetchPost />
                </>
            }
        </form>
    );
}

export default FormInfos;
