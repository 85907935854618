import { useEffect, useState } from "react";
import { ButtonLink } from "../../components/button/button";
import "./login.css";
import { Helmet } from "react-helmet-async";
import { motion } from "framer-motion";
import LoginForm from "../forms/loginForm";
import RegisterForm from "../forms/registerForm";
import { useAuthListener } from "../../contexts/contexts";
import { useNavigate } from "react-router-dom";

const Login = () => {


    const [logReg, setLogReg] = useState(false);
    const [text, setText] = useState('Entrar');

    const handleForms = () => {
        setLogReg((prevLogReg) => !prevLogReg);
        setText((prevText) => (prevText === "Entrar" ? "Criar Conta" : "Entrar"));
    };

    return (
        <div className="forms">
            <Helmet>
                <title>
                    {`Desenholy - ${text}`}
                </title>
                <meta name="description" content="Faça o login na desenholy e tenha um atendente 24 horas a disposição de seus clientes." />
            </Helmet>

            <div className="forms__container">

                <div className="forms__left">
                    {!logReg ? (
                        <motion.div
                            className="forms__left__motion"
                            key="formLogin"
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}>
                            <LoginForm />
                            <ButtonLink value="Não tenho uma conta" onClick={handleForms} />
                        </motion.div>
                    ) : (
                        <motion.div
                            className="forms__left__motion"
                            key="formRegister"
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}>
                            <RegisterForm />
                            <ButtonLink value="Já tenho uma conta" onClick={handleForms} />
                        </motion.div>
                    )}
                </div>

                <div className="forms__right gradient-bg">
                    <h1>Atendimento <span>24h</span> de seus clientes!</h1>
                    <p>A Desenholy, <b>de-sen-ro-la</b> sua vida para facilitar seu atendimento.</p>
                </div>

            </div>

        </div>
    );
};

export default Login;
