import { useEffect, useState } from "react";
import { useAuth } from "../../../../../contexts/contexts";
import { doGetDocumentOnStorage, doUpdateForm } from "../../../../../firebase/docs";
import { motion } from "framer-motion";

// JQUERY
import $ from "jquery";
import "jquery-mask-plugin";
import Loading from "../../../../../components/loading/loading";
import Message from "../../../../../components/alerts/message";

const Infos = ({handleEditInput, activated}) => {

    const {currentUser} = useAuth();
    

    const [load, setLoad] = useState(true);
    const [active, setActive] = useState(activated);
    const [formValues, setFormValues] = useState({});

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState({ title: "", text: "", emoji: "", class: ""});


    useEffect(() => {
        const fetchForm = async () => {
            const data = await doGetDocumentOnStorage();
            try {
                if (currentUser) {
                    setFormValues((prev) => ({
                        ...prev,
                        ...data
                    }));
                }
            } catch (error) {
                setLoad(true);
                console.log("Houve um erro no formulário do perfil.");   
            } finally {
                setLoad(false);
            }
        };
        fetchForm();
    }, [currentUser]);

    useEffect(() => {
        $(".cep").mask("00000-000");
        $(".cel").mask("(00) 0 0000-0000");
        $(".tel").mask("(00) 0000-0000");
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({ ...formValues, [id]: value });
    };

    const handleSubmit = async () => {
        try {
            await doUpdateForm(formValues);
            setActive(activated);
        } catch (error) {
            console.error("Erro ao salvar os dados: ", error);
            alert("Ocorreu um erro ao salvar os dados. Tente novamente mais tarde.");
        } finally {
            setTimeout(() => {
                setAlert(true);
            }, 2000);
        }
    };

    if (alert) {
        <Message title="teste" text="Dados Atualizados!" className="alert-success"/>
    }

    if (load) return <Loading />;

    return (
        <>
                {/* Informações Básicas da Empresa */}
            <motion.fieldset 
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                className="form__section form__section--empresa">
                <legend className="form__section-title">Informações Principais</legend>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="nomeEmpresa">Nome da Empresa <i>(*)</i></label>
                        <input 
                            className="form__input form__input--nome-empresa" 
                            type="text"
                            id="nomeEmpresa" 
                            value={formValues.nomeEmpresa}
                            onChange={handleInputChange}
                            placeholder="Digite o nome da sua empresa" 
                            disabled={active}
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="anoFundacao">Ano de Fundação </label>
                        <input 
                            className="form__input form__input--ano-fundacao" 
                            type="text" 
                            id="anoFundacao" 
                            value={formValues.anoFundacao}
                            onChange={handleInputChange}
                            placeholder="Ano de Fundação" 
                            disabled={active}
                        />
                    </div>
                </div>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="tipoEmpresa">Tipo de Empresa </label>
                        <input 
                            className="form__input form__input--tipo-empresa" 
                            type="text" 
                            id="tipoEmpresa" 
                            value={formValues.tipoEmpresa}
                            onChange={handleInputChange}
                            placeholder="Tipo de Empresa" 
                            disabled={active}
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="numeroFuncionarios">Número de Funcionários</label>
                        <input 
                            className="form__input form__input--numero-funcionarios" 
                            type="number" 
                            id="numeroFuncionarios" 
                            value={formValues.numeroFuncionarios}
                            onChange={handleInputChange}
                            placeholder="Número de Funcionários" 
                            disabled={active}
                        />
                    </div>
                </div>

                <label htmlFor="website">Website</label>
                <input 
                    className="form__input form__input--website" 
                    type="text" 
                    id="website" 
                    value={formValues.website}
                    onChange={handleInputChange}
                    placeholder="Digite seu website" 
                    disabled={active}
                />

                <label>Horário de Funcionamento</label>
                <input 
                    className="form__input form__input--horario" 
                    type="text" 
                    id="horarioFuncionamento"
                    value={formValues.horarioFuncionamento}
                    onChange={handleInputChange}
                    placeholder="Descreva os horários de atendimento na sua empresa" 
                    disabled={active}
                />

            </motion.fieldset>

            {/* Informações de Contato */}
            <motion.fieldset 
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                className="form__section form__section--contato">
                <legend className="form__section-title">Informações de Contato</legend>

                <label htmlFor="emailContato">E-mail de Contato <i>(*)</i></label>
                <input 
                    className="form__input form__input--email-contato" 
                    type="email" 
                    id="emailContato" 
                    value={formValues.emailContato}
                    onChange={handleInputChange}
                    placeholder="Digite o e-mail que gostaria de receber mensagens" 
                    disabled={active}
                />

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="telefoneSecundario">WhatsApp</label>
                        <input 
                            className="form__input form__input--telefone-secundario cel" 
                            type="text"
                            id="telefoneSecundario" 
                            value={formValues.telefoneSecundario}
                            onChange={handleInputChange}
                            placeholder="Digite o telefone com o whatsApp"
                            disabled={active}
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="telefonePrincipal">Telefone Principal</label>
                        <input 
                            className="form__input form__input--telefone-principal tel" 
                            type="text" 
                            id="telefonePrincipal" 
                            value={formValues.telefonePrincipal}
                            onChange={handleInputChange}
                            placeholder="Telefone Principal" 
                            disabled={active}
                        />
                    </div>
                </div>

            </motion.fieldset>

            {/* Endereço da Sede */}
            <motion.fieldset 
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                className="form__section form__section--endereco">
                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="cep">CEP </label>
                        <input 
                            className="form__input form__input--cep cep" 
                            type="text" 
                            id="cep"
                            value={formValues.cep}
                            onChange={handleInputChange}
                            placeholder="CEP" 
                            disabled={active}
                        />
                    </div>
                    <div className="form__section__divide">

                        <label htmlFor="numero">Número </label>
                        <input 
                            className="form__input form__input--numero"
                            type="number" 
                            id="numero" 
                            value={formValues.numero}
                            onChange={handleInputChange}
                            placeholder="Número" 
                            disabled={active}
                        />

                    </div>
                </div>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="cidade">Cidade</label>
                        <input 
                            className="form__input form__input--cidade" 
                            type="text" 
                            id="cidade" 
                            value={formValues.cidade}
                            onChange={handleInputChange}
                            placeholder="Cidade"
                            disabled={active}/>
                    </div>
                    <div className="form__section__divide">

                        <label htmlFor="bairro">Bairro </label>
                        <input 
                            className="form__input form__input--bairro" 
                            type="text" 
                            id="bairro" 
                            value={formValues.bairro}
                            onChange={handleInputChange}
                            placeholder="Bairro"
                            disabled={active}/>
                    </div>
                </div>

                <legend className="form__section-title">Endereço da Sede</legend>

                <label htmlFor="rua">Rua <i>(opcional)</i></label>
                <input 
                    className="form__input form__input--rua" 
                    type="text" 
                    id="rua" 
                    value={formValues.rua}
                    onChange={handleInputChange}
                    placeholder="Rua" 
                    disabled={active}
                />

                <label htmlFor="complemento">Complemento </label>
                <input 
                    className="form__input form__input--complemento" 
                    type="text" 
                    id="complemento" 
                    value={formValues.complemento}
                    onChange={handleInputChange}
                    placeholder="Complemento" 
                    disabled={active}
                />

                <label htmlFor="estado">Estado </label>
                <input 
                    className="form__input form__input--estado" 
                    type="text" 
                    id="estado" 
                    value={formValues.estado}
                    onChange={handleInputChange}
                    placeholder="Estado" 
                    disabled={active}
                />

            </motion.fieldset>

            {/* Observações Adicionais */}
            <motion.fieldset 
                initial={{scale: 0}}
                animate={{scale: 1}}
                exit={{scale: 0}}
                className="form__section form__section--observacoes">
                <legend className="form__section-title">Sobre</legend>
                <label>Descrição</label>
                <textarea 
                    className="form__input form__input--observacoes-adicionais" 
                    id="observacoesAdicionais"
                    value={formValues.observacoesAdicionais}
                    onChange={handleInputChange}
                    placeholder="Descreva detalhes sobre você/seu negócio caso queira detalhar algo.(opcional)"
                    disabled={active} />
            </motion.fieldset>

            <fieldset className="form__section form__section--buttons">
                <button 
                    type="button"
                    className="form__section__cancel"
                    onClick={handleEditInput}>
                    Cancelar
                </button>
                <button 
                className="form__section__save"
                type="button"
                onClick={handleSubmit}>
                    Salvar
                </button>
            </fieldset>
        </>
    );
}

export default Infos;