import { useState } from "react";
import { ButtonLink } from "../../components/button/button";
import Inputs from "../../components/inputs/inputs";
import { doSignInWithEmailAndPassword } from "../../firebase/auth";

export default function LoginForm() {

    // Login
    const [email, setEmail]     = useState('');
    const [pass, setPass]       = useState('');
    const [errors, setErrors]   = useState({ email: '', password: '', rePassword: '' });
    
    const [isSigningIn, setIsSigningIn] = useState(false);

    const passwordValid = (password) => {
        const hasMinLength   = password.length >= 6;
        const hasSpecialChar = /[!@#$%¨&*()_+-=^`´{}~}º/?|]/.test(password);

        if (!hasMinLength) {
            return "A senha deve conter 6 ou mais caracteres";
        } else if (!hasSpecialChar) {
            return "A senha tem que ter pelo menos um caracter especial";
        }
        return null; // Senha válida
    };

    const validateLogin = () => {
        let emailError = "";
        let passError = "";

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

        if (!email) {
            emailError = "O e-mail é obrigatório";
        } else if (!emailRegex) {
            emailError = "Digite um email válido";
        }

        passError = passwordValid(pass);

        setErrors({ email: emailError, password: passError });

        return !emailError && !passError;

    }

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        setErrors({ email: '', password: '' });
        const isValid = validateLogin();

        if (isValid) {
            setIsSigningIn(true);
            try {
                await doSignInWithEmailAndPassword(email, pass);
            
            } catch (err) {
                setIsSigningIn(false);
                console.error(err.code);
                
                // Verifique o tipo de erro retornado pelo Firebase
                if (err.code === "auth/user-not-found") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "Esse email não está cadastrado. Verifique ou crie uma conta."
                    }));
                } else if (err.code === "auth/wrong-password") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        password: "Senha incorreta. Tente novamente."
                    }));
                } else if (err.code === "auth/invalid-credential") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "Credenciais inválidas. Verifique seu email e senha."
                    }));
                } else {
                    console.error("Erro desconhecido:", err.code);
                }
                
            }
        }

    };
    
    return (
        <form className="login" onSubmit={handleSubmitLogin}>
            <h2>Desen<span>holy</span></h2>
            <div className="login__input">
            
                <Inputs 
                    className="forms__input"
                    type="email"
                    placeholder="Digite seu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />

                {errors.email && <span>{errors.email}</span>}
            
            </div>
            <div className="login__input">
            
                <Inputs 
                    className="forms__input"
                    type="password"
                    placeholder="Digite sua senha"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    />
                {errors.password && <span>{errors.password}</span>}

                <ButtonLink
                    className="login__btn"
                    value="Não consegue entrar?"
                    />
            
            </div>
            
            <button className="btn__login" type="submit" disabled={isSigningIn}>
                {isSigningIn ? "Entrando..." : "Entrar"}
            </button>


        </form>
    );
}