import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    updatePassword
} from "firebase/auth";

import { auth, db } from "./init"
import { doc, setDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";


export const doCreateUserWithEmailAndPassword = async (email, pass) => {
    try {
        // Criação do usuário no Firebase Auth
        const userCredential = await createUserWithEmailAndPassword(auth, email, pass);
        const user = userCredential.user;
        const uniqueId = uuidv4(); // Gera um ID único para o usuário

        // Criação do documento do usuário na coleção 'users'
        await setDoc(doc(db, "users", user.uid), {
            uuid: uniqueId, // Identificador único
            uid: user.uid, // UID do Firebase
            nomeEmpresa: "",
            plan: "gratuito",
            cnpj: "",
            inscricaoEstadual: "",
            ramoAtividade: "",
            anoFundacao: "",
            tipoEmpresa: "",
            numeroFuncionarios: 0,
            telefonePrincipal: "",
            telefoneSecundario: "",
            emailContato: user.email,
            website: "",
            endereco: {
                rua: "",
                numero: 123,
                complemento: "",
                bairro: "",
                cidade: "",
                estado: "",
                cep: ""
            },
            responsavel: {
                nomeCompleto: "",
                cpf: "",
                cargo: "",
                telefoneContato: "",
                email: user.email
            },
            faturamentoAnual: "",
            possuiInvestimentoExterno: false,
            redesSociais: {
                facebook: "",
                instagram: "",
                twitter: "",
                youtube: "",
                outros: [""]
            },
            produtosServicos: "",
            descricaoEmpresa: "",
            observacoesAdicionais: "",
            createdAt: new Date().toISOString() // Adiciona um timestamp de criação
        });

    } catch (error) {
        console.error("Erro ao criar usuário:", error.code, error.message);
        throw error;
    }
};

export const doSignInWithEmailAndPassword = async (email, pass) => {
    return signInWithEmailAndPassword(auth, email, pass);
};

export const doSignOut = async () => {
    return await auth.signOut();
};

export const doPasswordReset = (email) => {
    return sendPasswordResetEmail(auth, email);
};

export const doPasswordChange = (password) => {
    return updatePassword(auth.currentUser, password );
};

export const doSendEmailVerification = () => {
    return sendEmailVerification(auth.currentUser, { url: `${window.location.origin}/inicio` });
}