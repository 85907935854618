import { getUnicode } from 'emoji-dictionary';
import './message.css';

import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Message = (prop) => {
    return (
        <motion.div 
            className={` alert__message`}
            key        ="alertMessage"
            initial    ={{ opacity: 0}}
            animate    ={{ opacity: 1}}
            exit    ={{ opacity: 0}}> 
        
            <motion.div 
                className={`${prop.className} alert__container`}
                initial    ={{ scale: 0 }}
                animate    ={{ scale: 1 }}
                exit    ={{ scale: 0 }}>

                <div className="alert__message__title">
                    <h3>{prop.title} {getUnicode(`${prop.emoji}`)}</h3>
                </div>
                <div className="alert__message__text">
                    <p>{prop.text}</p><Link to={prop.to}>{prop.toText} {prop.iconLink}</Link>
                </div>

            </motion.div>
        
        </motion.div>
    );
}

export default Message;