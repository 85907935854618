import { useEffect, useState } from 'react';
import './service.css';
import { MdClose, MdEdit } from 'react-icons/md';
import { motion } from 'framer-motion';
import Loading from '../../../../../components/loading/loading';
import { useLocation } from 'react-router-dom';
import ServiceId from './serviceId';
import { deleteService, fetchService, updateService, uploadImage } from './ctxt/collectionServicos';
import { BiSolidImageAlt } from 'react-icons/bi';
import { useAuth } from '../../../../../contexts/contexts';
import { doGetDocumentOnStorage } from '../../../../../firebase/docs';
import { IoMdOpen } from 'react-icons/io';
import Message from '../../../../../components/alerts/message';

const Service = () => {
    const location = useLocation();
    const path = decodeURIComponent(location.pathname).split("/").pop();
    const { setPlan, currentUser } = useAuth();

    const [load, setLoad] = useState(false);
    const [allService, setAllService] = useState([]);
    const [editEnable, setEditEnable] = useState(false);
    const [editEnableService, setEditEnableService] = useState({});
    const [showServiceId, setShowServiceId] = useState(false); 
    const [updatedServiceData, setUpdatedServiceData] = useState({});

    const [countService, setCountService] = useState(0);

    // Carregar os serviços
    const loadService = async () => {
        setLoad(true);
        
        const data = await doGetDocumentOnStorage();
        const serviceList = await fetchService(path);

        try {
            setPlan(data.plan);
            
            setAllService(serviceList);

            const initialData = {};
            serviceList.forEach((service) => {
                initialData[service.id] = {
                    nome: service.nome || '',
                    desc: service.desc || '',
                    preco: service.preco || '',
                    imgURL: service?.imgURL || '',
                    nameImg: service?.nameImg || '', // Inclui o nome da imagem
                    imgPreview: null,
                    imgFile: null,
                };
            });
            setUpdatedServiceData(initialData);
            setCountService(allService.length);
            
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoad(false);
        }
    };

    useEffect(() => {
        loadService();
    }, [path]);

    const handleImageChange = (e, serviceId) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = ["image/jpeg", "image/png", "image/jpg"];
            const maxSize = 5 * 1024 * 1024; // 5MB

            if (!validTypes.includes(file.type)) {
                alert("Por favor, selecione uma imagem no formato JPG ou PNG.");
                return;
            }

            if (file.size > maxSize) {
                alert("A imagem deve ter no máximo 5MB.");
                return;
            }

            const previewURL = URL.createObjectURL(file);

            // Atualizar estado com o arquivo da imagem
            setUpdatedServiceData((prevState) => ({
                ...prevState,
                [serviceId]: {
                    ...prevState[serviceId],
                    imgURL: file,
                    imgPreview: previewURL,
                    nameImg: file.name,
                },
            }));
        }
    };

    const handleChange = (e, serviceId) => {
        const { name, value } = e.target;

        setUpdatedServiceData((prevState) => ({
            ...prevState,
            [serviceId]: {
                ...prevState[serviceId], 
                [name]: value,
            },
        }));
    };

    // Alterna a exibição do formulário de adicionar um novo serviço
    const toggleServiceId = () => {
        setShowServiceId((prev) => !prev);
    };

    const handleEditEnable = () => {
        setEditEnable((prev) => !prev);
        setEditEnableService({});
    };

    const handleEditEnableService = (serviceId) => {
        setEditEnableService((prevState) => ({
            ...prevState,
            [serviceId]: !prevState[serviceId], // Alterna o estado de edição para o serviço específico
        }));
    };
    

    const handleSubmit = async (docId) => {
        try {
            const serviceData = updatedServiceData[docId];
            let imgURL = serviceData?.imgURL;
            let nameImg = serviceData?.nameImg;
    
            // Se houver uma nova imagem, faça o upload
            if (serviceData?.imgURL) {
                const uploadedImage = await uploadImage(serviceData.imgURL);
                imgURL = uploadedImage.url; // Atualiza a URL da imagem
                nameImg = uploadedImage.name; // Atualiza o nome da imagem
            }
    
            const updatedData = {
                ...serviceData,
                imgURL,
                nameImg, // Adiciona o nome da imagem ao documento
            };
    
            // Atualiza o serviço no Firestore
            if (countService < 8) {
                await updateService(docId, updatedData);
            }
    
            // Atualiza o estado na interface
            setAllService((prevServices) =>
                prevServices.map((service) =>
                    service.id === docId ? { ...service, ...updatedData } : service
                )
            );
    
            alert("Serviço atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar serviço:", error);
        } finally {
            window.location.reload();
        }
    };
    
    

    const handleDelete = async (servId, nameImg) => {
        const confirmDelete = window.confirm("Tem certeza de que deseja excluir este serviço?");
        if (!confirmDelete) return;
    
        const isDeleted = await deleteService(servId, nameImg); // Chama a função de exclusão
        if (isDeleted) {
            setAllService((prevServices) =>
                prevServices.filter((service) => service.id !== servId) // Remove o serviço da lista local
            );
            alert("Serviço excluído com sucesso!");
        } else {
            alert("Erro ao excluir o serviço. Tente novamente.");
        }
    };

    if (load) return <Loading />;

    return (
        <div className="upload__service__container">
            <div className='upload__service__title'>
                <h2>{path}</h2>
                <p>Você pode adicionar até 8 serviços por categoria.</p>
            </div>
            {showServiceId ? (
                <ServiceId /> // Renderiza o componente ServiceId
            ) : allService.length > 0 ? (
                <div className="service">
                    {allService.map((service) => (
                        <motion.div
                            initial={{ opacity: 0, scale: 0 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0 }}
                            className="service__card"
                            key={service.id}
                        >
                            {editEnable && 
                                <div className='edit__specific__btns row'>
                                    <button
                                        className="edit__specific__btn"
                                        onClick={() => handleEditEnableService(service.id)}>
                                        {editEnableService[service.id] ? "cancelar" : "editar"}
                                    </button>
                                    <button
                                        className="edit__specific__btn btn--close"
                                        onClick={() => handleDelete(service.id, service.imgURL.name)}>
                                        <MdClose />
                                    </button>
                                </div>
                            }
                            {/* Exibição ou edição da imagem */}
                            <div className="service__card__image">
                                {!editEnableService[service.id] && (
                                    <img
                                        className="service__card__image"
                                        src={service.imgURL.url}
                                        alt={service.imgURL.name}
                                    />
                                )}
                                
                            </div>

                            {/* Exibição ou edição dos dados do serviço */}
                            {!editEnableService[service.id] ? (
                                <div className="service__card__infos">
                                    <h2 className='name'>{service.nome}</h2>
                                    <p className='desc'>{service.desc}</p>
                                    <p className='price'><span>{service.preco}</span> R$</p>
                                </div>
                            ) : (
                                <form
                                    onSubmit={(e) => { e.preventDefault(); handleSubmit(service.id); }}
                                    className="service__card__info service__card__editEnable"
                                >
                                
                                    <label htmlFor={`fileSelect-${service.id}`} className="image__label">
                                        {updatedServiceData[service.id]?.imgURL ? (
                                            <img
                                                src={updatedServiceData[service.id]?.imgPreview || updatedServiceData[service.id].imgURL}
                                                alt="Preview"
                                                className="upload__image__preview"
                                            />
                                        ) : (
                                            <BiSolidImageAlt className="upload__image__placeholder" />
                                        )}
                                        <span className="image__edit__label">Clique para editar a imagem</span>
                                    </label>
                                    
                                    <input
                                        id={`fileSelect-${service.id}`}
                                        className="file__service__sel"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e, service.id)}
                                        style={{ display: 'none' }}
                                    />

                                    <input
                                        type="text"
                                        value={updatedServiceData[service.id]?.nome || ''}
                                        onChange={(e) => handleChange(e, service.id)}
                                        name="nome"
                                        className="service__card__input sc__title"
                                    />
                                    <textarea
                                        value={updatedServiceData[service.id]?.desc || ''}
                                        onChange={(e) => handleChange(e, service.id)}
                                        name="desc"
                                        className="service__card__input sc__desc"
                                    />
                                    <input
                                        type="number"
                                        value={updatedServiceData[service.id]?.preco || ''}
                                        onChange={(e) => handleChange(e, service.id)}
                                        name="preco"
                                        className="service__card__input sc__price"
                                    />
                                    <button className="up__save__btn__service" type="submit">
                                        Salvar
                                    </button>
                                    
                                </form>
                            )}
                            
                        </motion.div>
                    ))}
                </div>
            ) : (
                <span>Você não tem nenhum serviço adicionado.</span>
            )}

            <div className='service__region__edit'>
                {countService >= 8 && 
                    <Message
                        className={`alert__message__fixed alert-fail`}
                        title= "Limite: 8 Serviços"
                        text= "Você pode adicionar apenas 8 serviços por categoria."
                        emoji= "thinking_face"/>
                }
                <div className='service__editEnable row'>
                    
                    {countService < 8 &&
                        <button
                            className="up__btn__add__new__service"
                            onClick={toggleServiceId}>
                            {showServiceId ? "Voltar" : "Adicionar mais serviços"}
                        </button>
                    }

                    <button
                        className="up__btn__add__new__service"
                        onClick={handleEditEnable}
                    >
                        {editEnable ? "Cancelar Edição" : "Editar"}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default Service;