import './button.css';
import { Link } from 'react-router-dom';


const Button = ({ className, onClick, disabled, title, icon, children }) => {
    return (
        <button 
            className={`button ${className}`}
            onClick={onClick}
            disabled={disabled}
            title={title}>
            {children} {icon}
        </button>
    );
};
const ButtonLink = (prop) => {

    return (
        <Link 
            onDragStart={prop.onDragStart}
            draggable={prop.drag}
            className={`button-link ${prop.className}`}
            onClick={prop.onClick}
            to={prop.to}>
            {prop.icon}<b>{prop.value}</b> {/* Usando o valor encurtado como texto do botão */}
        </Link>
    );
}

const ItemDrag = (prop) => {
    return (
        <div
            onDragStart={prop.onDragStart}
            draggable={prop.drag}
            className={`button-link ${prop.className}`}>
            <b>{prop.value}</b>{prop.icon}
        </div>
    );
}

export { Button, ButtonLink, ItemDrag };