import { useState } from "react";
import "./verifyWpp.css";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { db } from "../../../../firebase/init";

const VerifyWpp = ({ user }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [isPhoneRegistered, setIsPhoneRegistered] = useState(false);

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!phoneNumber) {
            setMessage('Por favor, insira um número de telefone válido.');
            return;
        }

        try {
            // Enviar o número para o Firebase Function para registrar
            const response = await axios.post('https://registernumber-k2sj6oyzoq-uc.a.run.app', {
                phoneNumber: phoneNumber,
            });

            // Sucesso na requisição: Mostra a tela para inserir o código
            setMessage('Código de verificação enviado! Por favor, insira o código.');
            setIsPhoneRegistered(true);
        } catch (error) {
            setMessage('Erro ao registrar número. Tente novamente.');
        }
    };

    const handleVerifyCode = async (event) => {
        event.preventDefault();

        if (!code) {
            setMessage('Por favor, insira o código de verificação.');
            return;
        }

        try {
            // Enviar o código para o Firebase Function para verificar
            const response = await axios.post('https://verifycode-k2sj6oyzoq-uc.a.run.app', {
                phoneNumber: phoneNumber,
                code: code,
            });

            // Salvar o número verificado no Firestore
            await db.collection('users').doc(user.uid).set({
                phoneNumber: phoneNumber,
                verified: true,
                timestamp: new Date(),
            });

            setMessage('Número verificado com sucesso!');
        } catch (error) {
            setMessage('Erro ao verificar o código. Tente novamente.');
        }
    };

    return (
        <form onSubmit={isPhoneRegistered ? handleVerifyCode : handleSubmit} className="verify__number">
            <Helmet>
                <title>Número do WhatsApp - Desenholy</title>
            </Helmet>
            <h3>Autenticação</h3>
            <p>Enviaremos um código para o número inserido logo abaixo. Aguarde um instante logo após "enviar código"</p>
            
            <input 
                className="verify__input"
                type="tel"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Digite o número do seu WhatsApp.."
                disabled={isPhoneRegistered}
            />

            {isPhoneRegistered && (
                <div>
                    <input
                        className="verify__input"
                        type="text"
                        value={code}
                        onChange={handleCodeChange}
                        placeholder="Digite o código recebido"
                    />
                </div>
            )}

            <button className="verify__button" type="submit">
                {isPhoneRegistered ? 'Verificar Código' : 'Enviar Código'}
            </button>

            {message && <p>{message}</p>}
        </form>
    );
};

export default VerifyWpp;
