import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { auth, db } from "./init";

export const doUpdateStepItem = async (stepId, formValues) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            console.log("Usuário precisa estar autenticado para atualizar etapas");
            return null;
        }

        if (!stepId || !stepId.trim()) {
            throw new Error("O ID do step é obrigatório.");
        }

        // Função para sanitizar o título e usá-lo como ID
        const sanitizeTitle = (title) => {
            return title
                .replace(/[/~#\[\]*%]/g, "")  
                .replace(/\s+/g, "_")         
                .toLowerCase();               
        };

        // Gera o ID sanitizado a partir do título (caso o ID não tenha sido fornecido)
        const sanitizedId = sanitizeTitle(stepId);

        // Referência para a subcoleção "steps" do usuário
        const stepsCollectionRef = collection(db, "users", user.uid, "steps");

        // Referência para o documento específico dentro da subcoleção
        const stepDocRef = doc(stepsCollectionRef, sanitizedId);

        // Cria um objeto com os dados a serem atualizados
        const updatedStepData = {
            ...formValues,  // Os dados que você quer atualizar
            updatedAt: new Date().toISOString(),  // Timestamp da atualização
        };

        // Atualiza o documento na subcoleção
        await updateDoc(stepDocRef, updatedStepData);

        console.log("Etapa atualizada com sucesso!");

        // Retorna os dados atualizados
        return updatedStepData;
    } catch (err) {
        console.error("Erro ao atualizar o step:", err);
        throw err;  // Lança o erro para ser tratado em outro lugar
    }
};

export const fetchStepByTitle = async (title) => {
    try {
        const user = auth.currentUser;
        if (!user) {
            console.log("Usuário precisa estar autenticado para buscar etapas");
            return null;
        }

        if (!title || !title.trim()) {
            throw new Error("O título da etapa é obrigatório.");
        }

        // Referência para a subcoleção "steps" do usuário
        const stepsCollectionRef = collection(db, "users", user.uid, "steps");

        // Cria uma consulta para buscar pelo título
        const stepQuery = query(stepsCollectionRef, where("titulo", "==", title));

        // Executa a consulta
        const querySnapshot = await getDocs(stepQuery);

        if (!querySnapshot.empty) {
            const steps = [];
            querySnapshot.forEach((doc) => {
                steps.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            // Retorna o primeiro resultado, já que o título é único
            return steps[0];
        } else {
            console.log("Nenhuma etapa encontrada com esse título.");
            return null;
        }
    } catch (err) {
        console.error("Erro ao buscar a etapa:", err);
        throw err;
    }
};

/**
 * Busca os documentos da subcoleção "steps" do usuário atual, ordenados por "createdAt".
 * @param {string} userId - ID do usuário atual.
 * @returns {Promise<Array>} Array de objetos contendo os dados de cada step.
 */
export const fetchUserSteps = async () => {
    const user = auth.currentUser; // Obtém o usuário atual autenticado

    try {
        // Verifica se o usuário está autenticado
        if (!user || !user.uid) {
            throw new Error("O ID do usuário é obrigatório.");
        }

        // Referência para a subcoleção "steps"
        const stepsCollectionRef = collection(db, "users", user.uid, "steps");

        // Cria uma consulta para ordenar por "createdAt"
        const stepsQuery = query(stepsCollectionRef, orderBy("createdAt"));

        // Obtém os documentos da consulta
        const querySnapshot = await getDocs(stepsQuery);

        // Transforma os documentos em um array de objetos
        return querySnapshot.docs.map((doc) => ({
            stepId: doc.id,
            ...doc.data(),
        }));
    } catch (error) {
        console.error("Erro ao buscar os steps:", error);
        throw error;
    }
};


/**
 * Sanitiza um título para ser usado como ID no Firestore.
 * Remove caracteres inválidos e converte para lowercase com underscores.
 * @param {string} title - O título a ser sanitizado.
 * @returns {string} - O título sanitizado.
 */
const sanitizeTitle = (title) => {
    return title
      .replace(/[/~#\[\]*%]/g, "")
      .replace(/\s+/g, "_")
      .toLowerCase();
  };
  
  /**
   * Deleta um documento na subcoleção `steps` do Firestore com base no título.
   * @param {string} userId - O ID do usuário dono da subcoleção.
   * @param {string} stepTitle - O título da etapa a ser deletada.
   */
export const doDeleteStepByTitle = async (stepTitle) => {
    const user = auth.currentUser;
    try {
        // Sanitiza o título para obter o ID do documento
        const sanitizedId = sanitizeTitle(stepTitle);

        // Referência ao documento no Firestore
        const stepDocRef = doc(collection(db, "users", user.uid, "steps"), sanitizedId);

        // Verifica se o documento existe antes de tentar deletar
        const docSnap = await getDoc(stepDocRef);

        if (!docSnap.exists()) {
        console.warn(`Documento com ID "${sanitizedId}" não encontrado.`);
        return;
        }

        // Deleta o documento
        await deleteDoc(stepDocRef);
        console.log(`Documento "${sanitizedId}" deletado com sucesso.`);
    } catch (error) {
        console.error("Erro ao deletar o documento:", error.message);
    }
};