import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase/init"
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const getCollectionPost = () => {
    const user = auth.currentUser;
    if (!user) throw Error("Usuário Não Autenticado!");
    return collection(db, "users", user.uid, "posts");
}

export const fetchAllPosts = async () => {
    const user = auth.currentUser; // Usuário autenticado
    try {
        if (!user) {
            console.error("Ocorreu um erro: usuário não autenticado");
            return [];
        }
        
        const usersCollectionRef = collection(db, "users"); // Coleção principal (usuários)
        const usersSnapshot = await getDocs(usersCollectionRef); // Pega todos os usuários
        const allPosts = [];

        // Itera sobre cada usuário e seus posts
        for (const userDoc of usersSnapshot.docs) {
            const userId = userDoc.id; // ID do usuário
            const postsCollectionRef = collection(db, `users/${userId}/posts`); // Subcoleção 'posts'
            const postsSnapshot = await getDocs(postsCollectionRef); // Pega os posts do usuário

            // Para cada post do usuário
            postsSnapshot.forEach((postDoc) => {
                const postData = postDoc.data();
                const postId = postDoc.id; // ID do post
                const authorId = postData.authorId || userId; // Garante que o authorId seja preenchido

                allPosts.push({
                    id: postId,       // Renomeia para 'id' para consistência
                    ...postData,      // Dados do post
                    userId,           // ID do autor do post (usuário dono do post)
                    authorId,         // ID do autor (separado caso tenha relevância)
                });
            });
        }

        return allPosts;
    } catch (error) {
        console.error("Erro ao buscar posts:", error);
        return [];
    }
};



// Salva um novo post
export const savePost = async (dataForm) => {
    const user = auth.currentUser;
    try {
        if (!dataForm.title || !dataForm.content) {
            throw new Error("Os campos 'title' e 'content' são obrigatórios.");
        }

        const postRef = getCollectionPost();
        const docRef = await addDoc(postRef, {
            ...dataForm,
            createdAt: serverTimestamp(),
            authorId: user.uid,
        });

        console.log("O documento foi criado no 'posts' com ID:", docRef.id);
        return docRef.id;
    } catch (error) {
        console.error("Erro ao salvar o post:", error);
        throw error;
    }
};

// Função para atualizar um post
export const updatePost = async (postId, dataForm) => {
    try {
        const postRef = doc(db, "users", auth.currentUser.uid, "posts", postId);
        await updateDoc(postRef, {
            ...dataForm,
            updatedAt: serverTimestamp(),  // Adiciona um campo 'updatedAt' para rastrear a última atualização
        });

    } catch (error) {
        console.error("Erro ao atualizar o post: ", error);
    }
};

// Deleta o post
export const deletePost = async (postId) => {
    try {
        const collectionRef = getCollectionPost();
        const pathPost = doc(collectionRef, postId)
        await deleteDoc(pathPost);
    } catch (er) {
        console.error(er.code);
        
    }
}

// Sobe a imagem associada ao post
export const uploadImagePost = async (file) => {
    try {
        const user = auth.currentUser;
        const storage = getStorage();
        const storageRef = ref(storage, `posts/${user.uid}/${file.name}`);
        
        // Faz o upload da imagem
        await uploadBytes(storageRef, file);
        
        // Obtém a URL da imagem após o upload
        const url = await getDownloadURL(storageRef);
        
        return url;
    } catch (error) {
        console.error('Erro ao fazer o upload da imagem:', error);
        throw new Error('Falha no upload da imagem');
    }
}

// Deleta a imagem associada ao post
export const deleteImagePost = async (imgUrl, path) => {
    try {
        const user = auth.currentUser;
        const storage = getStorage();
        const imageRef = ref(storage, `posts/${user.uid}/${path}`);

        await deleteObject(imageRef);
        console.log("Imagem tambem foi removida com sucesso");
    } catch (er) {
        const path = imgUrl.split('?')[0].split('o/')[1];
        console.error(er);
        console.log(path);
    }
}



// FetchPost 
// FetchPost 
// FetchPost 
// FetchPost 
export const fetchCollectionPost = async () => {
    try {
        const collectionRef = getCollectionPost();
        const querySnapshot = await getDocs(collectionRef);
        const posts = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return posts;
    } catch (er) {
        console.error(er);
        
    }
}

// Puxa um post individualmente
export const fetchUniquePost = async (postId) => {
    try {
        const collectionRef = getCollectionPost();
        const querySnapshot = await doc(collectionRef, postId);

        if (!querySnapshot) return null;

        const posts = [];
        querySnapshot.forEach((doc) => {
            posts.push({
                id: doc.id,
                ...doc.data()
            });
        });
        return posts;
    } catch (er) {
        console.error(er);
    }
}

//Adicionar comentaários ou likes
//Adicionar comentaários ou likes

//Adicionar comentaários ou likes
//Adicionar comentaários ou likes

export const addLikeToPost = async (authorId, postId) => {
    const user = auth.currentUser;

    if (!user) throw new Error("Usuário não autenticado!");

    const postRef = doc(db, "users", authorId, "posts", postId); // Referência para o post
    const likeRef = doc(db, "users", authorId, "posts", postId, "likes", user.uid); // Referência para o like do usuário atual

    try {
        // Verificar se o like já existe
        const likeSnapshot = await getDoc(likeRef);
        if (likeSnapshot.exists()) {
            // Se o like já existe, remove o like
            await deleteDoc(likeRef);
            await updateDoc(postRef, {
                likesCount: increment(-1), // Decrementa o contador de likes
            });
            console.log("Like removido com sucesso!");
        } else {
            // Caso contrário, adiciona o like
            await setDoc(likeRef, {
                userId: user.uid,
                likedAt: new Date(),
            });
            await updateDoc(postRef, {
                likesCount: increment(1), // Incrementa o contador de likes
            });
            console.log("Like adicionado com sucesso!");
        }
    } catch (error) {
        console.error("Erro ao alternar o like:", error);
    }
};

// Verifica se já deu like
export const checkUserReaction = async (authorId, postId) => {
    const user = auth.currentUser;

    if (!user) return false; // Retorna falso se o usuário não estiver autenticado

    try {
        const likeRef = doc(db, "users", authorId, "posts", postId, "likes", user.uid);
        const likeSnapshot = await getDoc(likeRef);

        return likeSnapshot.exists(); // Retorna true se o documento existir
    } catch (error) {
        console.error("Erro ao verificar reação do usuário:", error);
        return false;
    }
};

/**
 * Adiciona um comentário a um post.
 * @param {string} authorId - ID do autor do post.
 * @param {string} postId - ID do post.
 * @param {string} commentText - Texto do comentário.
 * @param {object} infos - Informações adicionais do usuário (ex: nome, imagem de perfil).
 */
export const addCommentOnPost = async (authorId, postId, commentText) => {
    const user = auth.currentUser;

    if (!user) {
        console.error("Usuário não autenticado!");
        throw new Error("Usuário não autenticado!");
    }

    if (!commentText || commentText.trim() === "") {
        console.error("O comentário não pode ser vazio!");
        throw new Error("O comentário não pode ser vazio!");
    }

    // Referências no Firestore
    const postRef = doc(db, "users", authorId, "posts", postId); // Referência para o post
    const commentsCollectionRef = collection(db, "users", authorId, "posts", postId, "comments"); // Referência para a subcoleção de comentários

    try {
        // Adiciona um novo comentário à subcoleção e captura a referência ao documento criado
        const commentRef = await addDoc(commentsCollectionRef, {
            userId: user.uid,
            commentText: commentText.trim(), // Remove espaços desnecessários
            createdAt: new Date(),
        });

        // Incrementa o contador de comentários no documento do post
        await updateDoc(postRef, {
            commentsCount: increment(1),
        });

        console.log("Comentário adicionado com sucesso!");

        // Retorna o ID do comentário criado e os dados do comentário
        return {
            id: commentRef.id,
            userId: user.uid,
            commentText: commentText.trim(),
            createdAt: new Date(),
        };
    } catch (error) {
        console.error("Erro ao adicionar comentário:", error);
        throw error; // Repassa o erro para tratamento em outro lugar
    }
};


/**
 * Busca os comentários de um post com informações do perfil do usuário.
 * @param {string} authorId - ID do autor do post.
 * @param {string} postId - ID do post.
 * @returns {Promise<Array>} - Lista de comentários com dados do usuário.
 */
export const fetchCommentsWithUserProfile = async (authorId, postId) => {
    try {
        // 1. Busca todos os comentários do post
        const commentsRef = collection(db, "users", authorId, "posts", postId, "comments");
        const commentsSnapshot = await getDocs(commentsRef);

        const commentsWithProfile = await Promise.all(
            commentsSnapshot.docs.map(async (commentDoc) => {
                const commentData = commentDoc.data();

                // 2. Busca o perfil do usuário usando o userId salvo no comentário
                const userRef = doc(db, "users", commentData.userId);
                const userSnapshot = await getDoc(userRef);
                const allComment = [];

                if (userSnapshot.exists()) {
                    const userData = userSnapshot.data();

                    // Retorna o comentário com as informações do perfil do usuário
                    return {
                        id: commentDoc.id,
                        ...commentData,
                        nomeProfile: userData.nomeEmpresa,
                        imgProfile: userData.imgProfile,
                    };
                } else {
                    // Caso o usuário tenha sido excluído ou não exista
                    return {
                        nomeProfile: "Usuário Desconhecido",
                        imgProfile: null,
                    };
                }
            })
        );

        return commentsWithProfile;
    } catch (error) {
        console.error("Erro ao buscar comentários com perfil do usuário:", error);
        throw error;
    }
};


// Deletar Comentário
export const deleteComment = async (authorId, postId, commentId) => {
    try {
        // Referência ao documento do comentário específico
        const postRef = doc(db, "users", authorId, "posts", postId);
        const commentRef = doc(db, "users", authorId, "posts", postId, "comments", commentId);
        
        // Exclui o documento
        await deleteDoc(commentRef);

        await updateDoc(postRef, {
            commentsCount: increment(-1)
        })

        console.log("Comentário excluído com sucesso!");
    } catch (error) {
        console.error("Erro ao excluir o comentário:", error);
        throw error;
    }
};