import "./homeLink.css";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ButtonLink } from "../../components/button/button";
import { useEffect, useState } from "react";
import { fetchUserData } from "../../firebase/docs";
import { useAuth } from "../../contexts/contexts";
import { FaThList } from "react-icons/fa";
import { motion } from "framer-motion";

const HomeLink = () => {

    const {uuid} = useParams();
    const {mobile} = useAuth();

    const location = useLocation();
    const path = location.pathname.split("/").pop();

    const [user, setUser] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    // Variantes para animação
    const sidebarVariants = {
        open: {
        x: 0, // Posição final
        transition: { type: "spring", stiffness: 300, damping: 30 },
        },
        closed: {
        x: "-100%", // Fora da tela (lado direito)
        transition: { type: "spring", stiffness: 300, damping: 30 },
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Buscar dados do usuário
                const data = await fetchUserData(uuid);
                setUser(data.userData);
                
            } catch (err) {
                console.error("Erro ao buscar os dados:", err);
            }
        };
        fetchData();
    }, [uuid, path]);
    
    return (
        <div className="homeLink">
            {!mobile &&
                <div className="class">
                    <div className="class__title">
                        <h3>{user.nomeEmpresa}</h3>
                    </div>
                    <ButtonLink
                        to={"categoria1"}
                        value={"categoria1"}
                        className={"categoria1" === path ? "active__class--item" : "class--item"}
                    />
                </div>
            }

            {mobile && 
                <motion.div 
                    variants={mobile && sidebarVariants}
                    initial={mobile ? "open" : "closed"}
                    animate={isOpen && mobile ? "open" : "closed"} 
                    className={mobile ? "class__mob" : "class"}>
                    <div className="class__title">
                        <h3>{user.nomeEmpresa}</h3>
                    </div>
                    <ButtonLink
                        to={"categoria1"}
                        value={"categoria1"}
                        onClick={() => setIsOpen(!isOpen)}
                        className={"categoria1" === path ? "active__class--item" : "class--item"}
                    />
                    <div className="class__height"></div>
                </motion.div>
            }

            <div className="class__result">
                <Outlet />
            </div>

            {mobile && 
                <div className="homeLink__mobile__nav">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <FaThList />
                    </button>
                </div>
            }
        </div>
    );
}

export default HomeLink;