import { IoCheckmark, IoClose } from "react-icons/io5";
import "./plans.css";
import { CgDanger } from "react-icons/cg";
import { MdOutlineOpenInNew } from "react-icons/md";
import { Helmet } from "react-helmet-async";

const Planos = () => {
    return (
        <div className="plan">

            <Helmet>
                <title>
                    {`Planos - Desenholy`}
                </title>
            </Helmet>

            <fieldset className="col free">
                <div className="plan__title">
                    <h2 className="plan__h2">Gratuito</h2><p className="plan__p--animate">Ideal para todos</p>
                </div>
                <div className="plan__info">
                    
                    <p>
                        <span><IoCheckmark /></span>
                        <span>Configurar o seu próprio fluxo de mensagens:</span>&nbsp; 
                        <span className="detail">5 etapas</span> de mensagens em seu fluxo. <b className="color">Essas mensagens serão visualizadas pelos seus clientes através do link compartilhado.</b>
                    </p>
                    
                    <p>
                        <span><IoCheckmark /></span>
                        <span>Categorias de Serviços:</span> &nbsp; 
                        Você pode adicionar até <span className="detail">2 categorias</span>.
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Serviços:</span> &nbsp; 
                        Você pode adicionar até <br /><span className="detail">4 serviços</span> por categoria.
                    </p>
                    
                    <div className="plan__info--sub">
                        <p><span>Você conseguirá ver:</span></p>
                        <p><span><IoCheckmark /></span> Quantos acessos obteve.</p>
                        <p><span><IoClose /></span> Qual produto é mais escolhido.</p>
                        <p><span><IoClose /></span> Data de acesso.</p>
                        <p><span><IoClose /></span> Capitar dados para contato.</p>
                        <p><span><IoClose /></span> Dados Completos.</p>
                    </div>

                    <div className="plan__info--attention">
                        <span><CgDanger /></span><p>Esse plano não possuí o bot para WhatsApp.</p>
                    </div>

                </div>
            </fieldset>

            <fieldset className="col standard">
                <div className="plan__title">
                    <h2 className="plan__h2">Padrão</h2><p className="plan__p--animate">Micro-Empresas</p>
                    <div className="plan__price">
                        <p>mensal</p>
                        <p>R$<span>99</span>,90</p> 
                        <p className="sub-price">
                         ou economize 10% na assinatura anual
                        </p>
                    </div>
                </div>
                <div className="plan__info">
                    
                    <p>
                        <span><IoCheckmark /></span>
                        <span>Configurar o seu próprio fluxo de mensagens:</span>&nbsp; 
                        <span className="detail">10 etapas</span> de mensagens em seu fluxo. <b className="color">Essas mensagens serão visualizadas pelos seus clientes através do link compartilhado.</b>
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Categorias de Serviços:</span> &nbsp; 
                        Você pode adicionar até <span className="detail">7 categorias</span>.
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Serviços:</span> &nbsp; 
                        Você pode adicionar até <br /><span className="detail">10 serviços</span> por categoria.
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Layout:</span> &nbsp; 
                        Personalize o seu perfil para que fique agradável para seus clientes.
                    </p>
                    
                    <div className="plan__info--sub">
                        <p><span>Você conseguirá ver:</span></p>
                        <p><span><IoCheckmark /></span> Quantos acessos obteve.</p>
                        <p><span><IoCheckmark /></span> Qual produto é mais escolhido.</p>
                        <p><span><IoCheckmark /></span> Data de acesso.</p>
                        <p><span><IoClose /></span> Capitar dados para contato.</p>
                        <p><span><IoClose /></span> Dados Completos.</p>
                    </div>

                    <div className="plan__info--success">
                        <span><IoCheckmark /></span><p>Bot para WhatsApp disponível.</p>
                    </div>

                    <div>
                        <p>- 1 mil conversas de WhatsApp inclusas</p>
                        <p>- Chatbot e 1 fluxo de automação</p>
                    </div>

                </div>
                <div className="plan__btn">
                    <button>
                        Assinar <MdOutlineOpenInNew />
                    </button>
                </div>
            </fieldset>

            <fieldset className="col premium">
                <div className="plan__title">
                    <h2 className="plan__h2">Avançado</h2><p className="plan__p--animate">Grandes Negócios</p>
                    <div className="plan__price">
                        <p>mensal</p>
                        <p>R$<span>499</span>,90</p> 
                        <p className="sub-price">
                         ou economize 20% na assinatura anual
                        </p>
                    </div>
                </div>
                <div className="plan__info">
                    
                    <p>
                        <span><IoCheckmark /></span>
                        <span>Configurar o seu próprio fluxo de mensagens:</span>&nbsp; 
                        <span className="detail">40 etapas</span> de mensagens em seu fluxo. <b className="color">Essas mensagens serão visualizadas pelos seus clientes através do link compartilhado.</b>
                    </p>
                    
                    <p>
                        <span><IoCheckmark /></span>
                        <span>Categorias de Serviços:</span> &nbsp; 
                        Você pode adicionar até <span className="detail">20 categorias</span>.
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Serviços:</span> &nbsp; 
                        Você pode adicionar até <br /><span className="detail">25 serviços</span> por categoria.
                    </p>

                    <p>
                        <span><IoCheckmark /></span>
                        <span>Layout:</span> &nbsp; 
                        Personalize o seu perfil para que fique agradável para seus clientes.
                    </p>
                    
                    <div className="plan__info--sub">
                        <p><span>Você conseguirá ver:</span></p>
                        <p><span><IoCheckmark /></span> Quantos acessos obteve.</p>
                        <p><span><IoCheckmark /></span> Qual produto é mais escolhido.</p>
                        <p><span><IoCheckmark /></span> Data de acesso.</p>
                        <p><span><IoCheckmark /></span> Capitar dados para contato.</p>
                        <p><span><IoCheckmark /></span> Dados Completos</p>
                        
                    </div>

                    <div className="plan__info--success">
                        <span><IoCheckmark /></span><p>Bot para WhatsApp disponível.</p>
                    </div>
                    <div>
                        <p>- 5 mil conversas de WhatsApp inclusas</p>
                        <p>- Chatbot e fluxos de automações ilimitados</p>
                    </div>

                </div>
                <div className="plan__btn">
                    <button>
                        Assinar <MdOutlineOpenInNew />
                    </button>
                </div>
            </fieldset>

        </div>
    );
}

export default Planos;