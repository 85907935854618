import { PiStarFour, PiStarFourFill } from "react-icons/pi";
import { addLikeToPost, checkUserReaction } from "../../../../../../../firebase/post";
import { useEffect, useState } from "react";
import LikeSpinner from "./likeSpinner";

const Like = ({ like }) => {
    const [reaction, setReaction] = useState(false); // Estado para o like do usuário
    const [likesCount, setLikesCount] = useState(like.likesCount || 0); // Estado para o contador de likes
    const [isLoading, setIsLoading] = useState(true); // Controle de carregamento

    // Verificar se o usuário já deu like
    useEffect(() => {
        const fetchReaction = async () => {
            setIsLoading(true);
            const userReaction = await checkUserReaction(like.authorId, like.id);
            setReaction(userReaction); // Define o estado inicial do botão de like
            setIsLoading(false);
        };

        fetchReaction();
    }, [like.authorId, like.id]);

    const formatLikesCount = (count) => {
        if (count >= 1_000_000) {
            return `${(count / 1_000_000).toFixed(1).replace(".0", "")}M`; // Ex: 1.2M ou 1M
        } else if (count >= 100_000) {
            return `${Math.floor(count / 1_000)}k`; // Ex: 100k
        } else if (count >= 10_000) {
            return `${(count / 1_000).toFixed(1).replace(".0", "")}k`; // Ex: 10.5k ou 10k
        } else {
            return count.toString(); // Exibe o número original
        }
    };

    // Controle do botão de like
    const handleReaction = async () => {
        const newReaction = !reaction;
        setReaction(newReaction); 
        setLikesCount((prev) => prev + (newReaction ? 1 : -1));

        try {
            // Atualiza o banco de dados
            await addLikeToPost(like.authorId, like.id, newReaction); // Você pode passar `newReaction` se precisar no backend
        } catch (error) {
            console.error("Erro ao atualizar o like:", error);

            // Reverte a alteração no DOM em caso de erro
            setReaction(!newReaction);
            setLikesCount((prev) => prev - (newReaction ? 1 : -1));
        }
    };

    if (isLoading) {
        return <LikeSpinner />; // Indicador de carregamento
    }

    return (
        <button onClick={handleReaction} className="reaction__btn star">
            {reaction ? <PiStarFourFill /> : <PiStarFour />}
            {likesCount > 0 && <p>{formatLikesCount(likesCount)}</p>}
        </button>
    );
};

export default Like;
