import "./header.css";
import { useLocation } from "react-router-dom";
import { doSignOut } from "../../../../../firebase/auth";
import { ButtonLink } from "../../../../../components/button/button";

import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BiMenu } from "react-icons/bi";
import { IoColorFill } from "react-icons/io5";
import { FaSquare, FaUser } from "react-icons/fa6";
import { IoIosExit } from "react-icons/io";
import { MdDesignServices, MdInsertDriveFile } from "react-icons/md";

const Header = () => {
    const [drop, setDrop] = useState(false);
    const [activeButton, setActiveButton] = useState("");

    const location = useLocation();
    const path = location.pathname;
    const isHomePage = location.pathname.includes("/perfil/pagina-inicial");
    
    useEffect(() => {
        const route = path.split("/")[2];
        setActiveButton(route || "perfil"); 
    }, [path]);

    const handleSignOut = async () => {
        const quit = window.confirm("Você deseja mesmo sair?");

        try {
            if (quit) {
                await doSignOut();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Erro ao sair:", error.code);
        }
    };

    const handleDropdownToggle = () => setDrop((prev) => !prev);

    const dropdownVariants = {
        closed: {
            height: 0,
            opacity: 0,
            overflow: "hidden",
            transition: { duration: 0.3, ease: "easeInOut" }
        },
        open: {
            height: "auto",
            opacity: 1,
            overflow: "hidden",
            transition: { duration: 0.3, ease: "easeInOut" }
        }
    };

    return (
        <header>
            {/* Lado Esquerdo */}
            <div className="header__lmr header__left">
                <h1 className="brand">Desen<span>holy</span></h1>
            </div>

            {/* Centro */}
            <div className="header__lmr header__middle">
                {/* Espaço reservado para elementos futuros */}
            </div>

            <div className="header__lmr header__right">
                {/* Botão Dropdown */}

                <div className="menu__header">
                    {/* Meu Perfil */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "perfil" ? "header__btn--disabled" : ""}`}
                        to="/perfil"
                        onClick={activeButton === "perfil" ? undefined : handleDropdownToggle}
                        value="Perfil"
                        icon={<FaUser />}
                    />

                    {/* Meu Fluxo */}
                    <ButtonLink
                        className={`option__menu ${isHomePage ? "header__btn--disabled" : ""}`}
                        to="/perfil/pagina-inicial"
                        onClick={isHomePage ? undefined : handleDropdownToggle}
                        value="Pagina Inicial"
                        icon={<FaSquare />}
                    />

                    {/* Serviços */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "servicos" ? "header__btn--disabled" : ""}`}
                        to="/perfil/servicos"
                        onClick={activeButton === "servicos" ? undefined : handleDropdownToggle}
                        value="Serviços"
                        icon={<MdDesignServices />}
                    />

                    {/* Sair */}
                    <ButtonLink
                        className="option__menu"
                        to="/"
                        onClick={handleSignOut}
                        value="Sair"
                        icon={<IoIosExit />} />
                        
                </div>

                {/*  */}
                {/* DROPDOWN AQUI ABAIXO */}
                {/*  */}

                <div className="menu__dropdown">
                    <button
                        className="dropmenu"
                        onClick={handleDropdownToggle}
                        aria-expanded={drop}
                        aria-label="Abrir Menu">
                        <BiMenu />
                    </button>

                    {/* Menu Dropdown */}
                    <motion.div
                        className="dropdown"
                        initial="closed"
                        animate={drop ? "open" : "closed"}
                        variants={dropdownVariants}
                    >
                        {/* Meu Perfil */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "perfil" ? "header__btn--disabled" : ""}`}
                            to="/perfil"
                            onClick={activeButton === "perfil" ? undefined : handleDropdownToggle}
                            value="Perfil"
                            icon={<FaUser />}
                        />

                        {/* Meu Fluxo */}
                        <ButtonLink
                            className={`option__menu ${isHomePage ? "header__btn--disabled" : ""}`}
                            to="/perfil/pagina-inicial"
                            onClick={isHomePage ? undefined : handleDropdownToggle}
                            value="Pagina Inicial"
                            icon={<FaSquare />}
                        />

                        {/* Dados Listados */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "dados" ? "header__btn--disabled" : ""}`}
                            to="/perfil/dados"
                            onClick={activeButton === "dados" ? undefined : handleDropdownToggle}
                            value="Dados"
                            icon={<MdInsertDriveFile />}
                        />

                        {/* Serviços */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "servicos" ? "header__btn--disabled" : ""}`}
                            to="/perfil/servicos"
                            onClick={activeButton === "servicos" ? undefined : handleDropdownToggle}
                            value="Serviços"
                            icon={<MdDesignServices />}
                        />

                        {/* Personalização */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "aparencia" ? "header__btn--disabled" : ""}`}
                            to="/perfil/aparencia"
                            onClick={activeButton === "aparencia" ? undefined : handleDropdownToggle}
                            value="Aparência"
                            icon={<IoColorFill />}
                        />

                        {/* Sair */}
                        <ButtonLink
                            className="option__menu"
                            to="/"
                            onClick={handleSignOut}
                            value="Sair"
                            icon={<IoIosExit />}
                        />
                    </motion.div>
                </div>
            </div>
        </header>
    );
};

export default Header;
