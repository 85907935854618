import "./home.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/header/header";
import { PiListPlusBold, PiListPlusFill, PiStackOverflowLogoBold, PiStackOverflowLogoFill, PiStarFour, PiStarFourBold, PiStarFourFill } from "react-icons/pi";
import { useAuth } from "../../../contexts/contexts";
import { FaCheck } from "react-icons/fa";
import { RiCloseLargeFill } from "react-icons/ri";
import { useState } from "react";
import { doUpdateSteps } from "../../../firebase/docs";
import { TbUser, TbUserFilled } from "react-icons/tb";
import { BiCollection, BiSolidCollection } from "react-icons/bi";

export default function Home() {

  // AuthProvider
  const { mobile } = useAuth();

  // location
  const location = useLocation();
  const path = decodeURIComponent(location.pathname).split("/").pop();
  const pathClass = decodeURIComponent(location.pathname).split("/")[2];
  const navigate = useNavigate();

  // useStates
  const [msg, setMsg] = useState(false);

  if (msg) {
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  const perfilBtn = () => {
    navigate("/perfil");
  }

  const spaceBtn = () => {
    navigate("pagina-inicial");
  }
  
  const serviceBtn = () => {
    navigate("servicos")
  }



  return (
    <section className="container">
      <div className="container__lmr container__left">
        <Header />
      </div>

      <div className="container__middle">
        <Outlet />
      </div>

      <div className="container__lmr container__right">
        
        {/* Coloque os conteúdos da direita aqui */}
        {mobile && 
          <div className="flow__footer__step row">
            <button disabled={path === "perfil"} onClick={perfilBtn}>
              {path === "perfil" ? <TbUserFilled /> : <TbUser />}
            </button>
            <button onClick={spaceBtn} disabled={path === "pagina-inicial"} className={path === "pagina-inicial" && "spinnerStarSpace"}>
              {path === "pagina-inicial" ? <PiStarFourFill />  : <PiStarFour /> }
            </button>
            <button onClick={serviceBtn} disabled={path === "servicos" || pathClass === "servicos"}>
              {path === "servicos" || pathClass === "servicos" ? <BiSolidCollection /> : <BiCollection />}
            </button>
          </div>
        }
      </div>
    </section>
  );
}
