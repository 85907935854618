import { useLocation } from "react-router-dom";
import "./stepComponent.css";
import { useEffect, useState } from "react";
import { doUpdateStepItem, fetchStepByTitle, fetchUserSteps } from "../../../../firebase/steps";
import Message from "../../../../components/alerts/message";

export const StepComponent = () => {


    // Location
    const location = useLocation();
    const path = decodeURIComponent(location.pathname).split("/").pop();

    //consts
    const [steps, setSteps] = useState([]);
    const [dataStep, setDataStep] = useState({});
    
    // Alert
    const [alert, setAlert] = useState(false);
    const [msg, setMsg] = useState({});

    useEffect(() => {
        const fetchSteps = async () => {
            const stepsServer = await fetchUserSteps();
            const fetchStep = await fetchStepByTitle(path);
            setSteps(stepsServer);
            if (fetchStep) {
                setDataStep(fetchStep);
            }
        }
        fetchSteps();
    }, [path]);

    const [formData, setFormData] = useState({
        stepId: path,
        type: "sendMessage", // Tipo padrão
        message: "",
        expectedResponses: [],
        apiUrl: "",
        nextStepId: null,
    });

    const handleChange = (field, value) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleTagChange = (e) => {
        const input = e.target.value;
        const newResponses = input.split(",");
        handleChange("expectedResponses", newResponses)
    }

    const handleTagRemove = (index) => {
        // Remove uma tag específica
        const updatedResponses = formData.expectedResponses.filter((_, idx) => idx !== index);
        handleChange("expectedResponses", updatedResponses);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const updatedStep = await doUpdateStepItem(path, formData);
            if (updatedStep) {
                setAlert(true);
                setMsg({
                    title: "Etapa Atualizada",
                    message: `Sua etapa "${path}" foi atualizada com sucesso.`,
                    class: "alert-success"
                });
            }
        } catch (e) {
            if (e) {
                setAlert(true);
                setMsg({
                    title: "Erro",
                    message: `Ocorreu algum erro ao tentar atualizar a etapa. Tente novamente mais tarde`,
                    class: "alert-fail"
                });
            }
            console.log(e.code);
        } finally {
            if (msg) {
                setTimeout(() => {
                    setAlert(false);
                }, 4000);
            }
        }
    };
    
    return (
        <div className="col">
            {alert && 
                <Message 
                    title={msg.title}
                    text={msg.message}
                    emoji="smile"
                    className={msg.class}/>
            }
            <div className="step__component col">
                <h3>Configurar <span>{path}</span></h3>

                <div className="step__label row">
                    <label>
                        <strong>Identificador</strong>
                        <input 
                            type="text"
                            value={path}
                            disabled={path}
                            placeholder="ID único da etapa"/>
                    </label>
                    <label>
                        <strong>Tipo de Etapa</strong>
                        <select
                            value={formData.type}
                            onChange={(e) => handleChange("type", e.target.value)}>
                                <option value="sendMessage">Enviar Mensagem</option>
                                <option value="waitResponse">Esperar Resposta</option>
                                <option value="conditional">Condicional</option>
                                <option value="apiCall">Chamar API</option>
                                <option value="endFlow">Encerrar Fluxo</option>
                        </select>
                    </label>
                </div>

                {formData.type === "sendMessage" && (
                    <div className="step__label">
                        <label>
                            <strong>Mensagem</strong>
                            <textarea
                                value={dataStep?.message || formData.message }
                                onChange={(e) => handleChange("message", e.target.value)}
                                placeholder="Digite a mensagem a ser enviada" />
                        </label>
                    </div>
                )}

                {formData.type === "waitResponse" && (
                    <div className="step__label col">
                        <label>
                            <strong>Respostas do usuário</strong>
                            <input 
                                type="text"
                                value={formData.expectedResponses.join(",")}
                                onChange={handleTagChange}
                                placeholder="Separe as respostas por vírgula (ex: sim, não)"/>
                        </label>
                        {formData.expectedResponses.length > 0 &&
                            <div className="tags-container">
                                {formData.expectedResponses.length > 0 &&
                                    formData.expectedResponses.map((response, index) => (
                                        <span
                                            key={index}
                                            className="tag"
                                            onClick={() => handleTagRemove(index)} // Permite remover a tag ao clicar
                                        >
                                            {response}
                                            <button className="remove-tag" onClick={(e) => { e.stopPropagation(); handleTagRemove(index); }}>
                                                ×
                                            </button>
                                        </span>
                                    ))}
                            </div>
                        }   
                    </div>
                )}

                {formData.type === "apiCall" && (
                    <div className="step__label">
                        <label>
                            <strong>URL da API</strong>
                            <input 
                                type="text"
                                value={formData.apiUrl}
                                onChange={(e) => handleChange("apiURL", e.target.value)}
                                placeholder="Digite a URL da API"/>
                        </label>
                    </div>
                )}

                <div className="step__label">
                    <label>
                        <strong>Próxima Etapa</strong>
                        <select
                            value={formData.nextStepId || ""}
                            onChange={(e) => handleChange("nextStepId", e.target.value)}>
                                <option>
                                    Selecione a próxima etapa
                                </option>
                                {steps && steps.length > 0 ? (
                                    steps.map((step, index) => (
                                    <option key={step.id || index} value={step.stepId}>
                                        {step.titulo}
                                    </option>
                                    ))
                                ) : (
                                    <option disabled>Não há etapas disponíveis</option>
                                )}
                        </select>
                    </label>
                </div>

                <button
                    onClick={handleSave}>Salvar Etapa</button>

            </div>
        </div>
    );
}