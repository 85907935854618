import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useAuth } from "../../../../../contexts/contexts";
import { BiSolidImageAlt } from "react-icons/bi";
import Message from "../../../../../components/alerts/message";
import { IoMdOpen } from "react-icons/io";
import { auth, db } from "../../../../../firebase/init";
import { fetchService, insertService, uploadImage } from "./ctxt/collectionServicos";

const ServiceId = () => {

    const { servicesFlow } = useAuth();

    // location
        const location = useLocation();
        const path = decodeURIComponent(location.pathname).split("/").pop();

    // consts
        const [newUpload, setNewUpload] = useState(false);
        const [dataService, setDataService] = useState(null);
        const [sizeServices, setSizeServices] = useState(null);
        const [btnAdd, setBtnAdd] = useState(false);
        const [imageFile, setImageFile] = useState(null);
        const [imagePreview, setImagePreview] = useState(null);
        const [alertStatus, setAlertStatus] = useState(null);
        const [message, setMessage] = useState({});

        const [formValues, setFormValues] = useState({
            title: "",
            desc: "",
            price: "",
            imgURL: "",
        });

    // Verificar se todos os campos estão preenchidos
    useEffect(() => {
        setBtnAdd(formValues.title && formValues.desc && formValues.price && imageFile);
    }, [formValues, imageFile]);

    

    // Atualizar a mensagem de alerta após 5 segundos
    useEffect(() => {
        if (alertStatus) {
            const timer = setTimeout(() => setAlertStatus(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [alertStatus]);

    useEffect(() => {
        return () => {
            if (imagePreview) URL.revokeObjectURL(imagePreview);
        };
    }, [imagePreview]);
    
    const resetForm = () => {
        if (imagePreview) URL.revokeObjectURL(imagePreview);
        setFormValues({ title: "", desc: "", price: "", imgURL: "" });
        setImageFile(null);
        setImagePreview(null);
        setMessage({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const validTypes = ["image/jpeg", "image/png", "image/jpg"];
            const maxSize = 2 * 1024 * 1024; // 2MB
    
            if (!validTypes.includes(file.type)) {
                alert("Por favor, selecione uma imagem no formato JPG ou PNG.");
                return;
            }
    
            if (file.size > maxSize) {
                alert("A imagem deve ter no máximo 2MB.");
                return;
            }
    
            if (imagePreview) URL.revokeObjectURL(imagePreview);
            setImageFile(file); // Apenas salva o arquivo no estado
            setImagePreview(URL.createObjectURL(file)); // Atualiza o preview
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Verificação dos campos obrigatórios
        if (!formValues.title || !formValues.desc || !formValues.price || !imageFile) {
            alert("Por favor, preencha todos os campos obrigatórios e selecione uma imagem.");
            return;
        }
    
        try {
            // Faz o upload da imagem
            const imageUrl = await uploadImage(imageFile);
            const serviceData = {
                imgURL: imageUrl,
                status: "ativo", 
                nome: formValues.title || "",
                desc: formValues.desc || "",
                preco: formValues.price || "",
                categoria: path
            };

            setMessage({
                title: "Serviço Adicionado",
                text: "Seu serviço foi adicionado com sucesso. É necessário atualizar a página.",
                emoji: "smile",
            });
            setAlertStatus("alert-success");
    
           await insertService(path, serviceData);
        } catch (error) {
            if (error.message.includes("Você atingiu o número máximo de" || "Você atingiu o limite de etapas do seu plano. Limite:")) {
                setImageFile(null);
                setMessage({
                    title: "Limite: 8 Serviços",
                    text: "Você pode adicionar apenas 4 serviços por categoria.",
                    emoji: "thinking_face",
                });
            } 
            setAlertStatus("alert-fail");
        } finally {
            resetForm()
        }
    };
    
    // Buscar dados dos serviços
    useEffect(() => {
        const fetchServices = async () => {
            const servicos = await fetchService(path);
            setDataService(servicos);
            if (dataService) {
                setNewUpload(false);
            }
        };
        fetchServices();
    }, []);
    
    return (
        
        <motion.div
            className="up__btn upload__btn__new"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}>

            <div className="up__btn upload__btn__add">

                {message && alertStatus === "alert-success" && 
                    <Message
                        className={`alert__message__fixed ${alertStatus}`}
                        emoji={message.emoji}
                        title={message.title}
                        text={message.text}
                    />
                }
            
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="title"
                        value={formValues.title}
                        onChange={handleInputChange}
                        className='desc__product'
                        placeholder="Insira o nome do produto.."
                    />
                    <div className="upload__btn__new__fields">
                        <fieldset>
                            <label htmlFor="fileSelect">
                                {imagePreview ? (
                                    <img src={imagePreview} alt="Preview" className="upload__image__preview" />
                                ) : (
                                    <BiSolidImageAlt />
                                )}
                            </label>
                            <input
                                id="fileSelect"
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </fieldset>
                        <textarea
                            name="desc"
                            value={formValues.desc}
                            onChange={handleInputChange}
                            placeholder="Descrição do produto/serviço"
                            maxLength={200}
                        />
                        <input
                            type="number"
                            name="price"
                            value={formValues.price}
                            onChange={handleInputChange}
                            placeholder="Preço"
                        />
                    </div>
                        
                    <button className="upload__add">Adicionar</button>
                </form>
            
            </div>

        </motion.div>

    );
}

export default ServiceId;