import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './routes/appRoutes';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker'; 
import { AuthProvider } from './contexts/contexts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <HelmetProvider>
      <AppRoutes />
    </HelmetProvider>
  </AuthProvider>
);

// Registra o service worker
serviceWorker.register();
