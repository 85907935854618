import App from '../App';
import CompleteMessage from '../components/alerts/page/complete';
import Bot from '../pages/botPage/bot';
import Ajuste from '../pages/login/private/ajuste/ajuste';
import Analise from '../pages/login/private/analise/analise';
import Clientes from '../pages/login/private/clientes/clientes';
import FormInfos from '../pages/login/private/form/form';
import Home from '../pages/login/private/home';
import Servicos from '../pages/login/private/servicos/servicos';
import Landing from '../landing/landing';
import About from '../landing/section/about/about';
import Step from '../pages/login/private/homePage/step';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Aparencia from '../pages/login/private/aparencia/aparencia';
import Planos from '../pages/login/private/form/plans';
import Service from '../pages/login/private/components/service/service';
import VerifyWpp from '../pages/login/private/verifyWpp/verifyWpp';
import CodeWpp from '../pages/login/private/verifyWpp/codeWpp';
import HomePage from '../pages/login/private/homePage/homePage';
import HomeLink from '../pages/link/homeLink';
import HomeService from '../pages/link/homeService';

const AppRoutes = () => {

    return (
        <BrowserRouter >
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/entrar" element={<App />} />
                <Route path="/sobre" element={<About />} />
                <Route path="/registro-concluido" element={<CompleteMessage />} />
                <Route path="/:uuid" element={<HomeLink />}>
                    <Route path=":id" element={<HomeService />} />
                </Route>

                {/* Rota protegida /perfil */}
                <Route path="/perfil" element={<Home />}>
                    {/* Rotas internas */}
                    <Route index element={<FormInfos />} />
                    <Route path='number' element={<VerifyWpp />}>
                        <Route path=':id' element={<CodeWpp />}  />
                    </Route>
                    <Route path="planos" element={<Planos />} />
                    <Route path="servicos" element={<Servicos />}>
                        <Route path=':id' element={<Service />} />
                    </Route>
                    <Route path="analises" element={<Analise />} />
                    <Route path="ajustes" element={<Ajuste />} />
                    <Route path="dados" element={<Clientes />} />
                    <Route path="pagina-inicial" element={<HomePage />}>
                        <Route path=":id" element={<Step />} />
                    </Route>
                    <Route path="personalizar" element={<Aparencia />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRoutes;
