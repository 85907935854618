import "./landing.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/header/header";
import Divide from "./components/header/divide";
import Hero from "./section/hero/hero";
import Footer from "./components/footer/footer";
import How from "./section/how/how";


const Landing = () => {


    useEffect(() => {
        if (isPWA()) {
        }
    }, []);

    const isPWA = () => {
        return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    }

    
    return (
        <div className="landing">
            <Divide 
                text="A criatividade aumenta diante do estresse e pressão maior."/>
            <Header />
            <Hero />
            <How />
            <Footer />
        </div>
    );
}

export default Landing;