import { Helmet } from "react-helmet-async";
import "./verifyWpp.css"
import { useState } from "react";
import { Outlet } from "react-router-dom";

const CodeWpp = () => {
    const [verify, setVerify] = useState(true);
    return (
        <div className="verify__number">
            <Helmet>
                <title>Código - Desenholy</title>
            </Helmet>
            {verify ? (
                <form>
                    <h3>Código Enviado!</h3>
                    <p>Insira seu código aqui..</p>
                    <input 
                        className="verify__input"
                        type="phone"
                        placeholder="Insira o código aqui.."/>
                    <button className="verify__button">
                        Autenticar
                    </button>
                </form>
            ) : (
                <Outlet />
            )}
        </div>
    );
}

export default CodeWpp;