import { useState } from "react";
import { Button } from "../../components/button/button";
import Inputs from "../../components/inputs/inputs";
import { doCreateUserWithEmailAndPassword } from "../../firebase/auth";
import { useNavigate } from "react-router-dom";
import { verifyEmailWithHunter } from "../../contexts/contexts";

export default function RegisterForm() {
    const [formData, setFormData] = useState({ email: '', password: '', rePassword: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const passwordValid = (password) => {
        const hasMinLength = password.length >= 6;
        const hasSpecialChar = /[!@#$%¨&*()_+-=^`´{}~}º/?|]/.test(password);
        if (!hasMinLength) return "A senha deve conter 6 ou mais caracteres";
        if (!hasSpecialChar) return "A senha tem que ter pelo menos um caracter especial";
        return null;
    };

    const validate = () => {
        let errors = {};
        if (!formData.email) errors.email = 'O e-mail é obrigatório!';
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) errors.email = 'Insira um e-mail válido!';
        if (formData.password !== formData.rePassword) errors.rePassword = 'As senhas não coincidem';
        const passwordError = passwordValid(formData.password);
        if (passwordError) errors.password = passwordError;

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        if (!validate()) return;

        try {
            const isEmailValid = await verifyEmailWithHunter(formData.email).catch((error) => {
                console.error("Erro ao verificar e-mail:", error);
                setErrors((prev) => ({ ...prev, email: 'Erro ao validar o e-mail. Tente novamente.' }));
                return false;
            });

            if (!isEmailValid) return;

            await doCreateUserWithEmailAndPassword(formData.email, formData.password);
            navigate('/registro-concluido', { state: { tag_email: formData.email } });
        } catch (err) {
            if (err.code === "auth/email-already-in-use") {
                setErrors((prev) => ({ ...prev, email: "Esse email já está cadastrado" }));
            } else {
                setErrors((prev) => ({ ...prev, generic: "Ocorreu um erro. Tente novamente." }));
            }
        }
    };

    return (
        <form className="register" onSubmit={handleSubmitRegister} method="POST">
            <h2>Criar <span>Conta</span></h2>
            <div className="register__input">
                <Inputs
                    id="email"
                    name="email"
                    className="forms__input"
                    type="email"
                    placeholder="Digite seu email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <span>{errors.email}</span>}
            </div>
            <div className="register__input">
                <Inputs
                    className="forms__input"
                    type="password"
                    name="password"
                    placeholder="Crie sua senha"
                    value={formData.password}
                    onChange={handleChange}
                />
                {errors.password && <span>{errors.password}</span>}
            </div>
            <div className="register__input">
                <Inputs
                    className="forms__input"
                    type="password"
                    name="rePassword"
                    placeholder="Repita sua senha"
                    value={formData.rePassword}
                    onChange={handleChange}
                />
                {errors.rePassword && <span>{errors.rePassword}</span>}
            </div>
            {errors.generic && <span>{errors.generic}</span>}
            <Button className="register__btn" type="submit">
                Criar Conta
            </Button>
        </form>
    );
}
