import "./opt/card.css";
import { useLocation } from "react-router-dom";

import { StepComponent } from "./stepComponent";

const Step = () => {

    const location = useLocation();
    const path = decodeURIComponent(location.pathname).split("/").pop();

    return (
        <div className="flow__mob">

            <div className="flow__mob__row row">
                <StepComponent />
            </div>
        </div>
    )
}

export default Step;