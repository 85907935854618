import { onAuthStateChanged } from "firebase/auth";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { auth } from "../firebase/init";
import Loading from "../components/loading/loading";
import axios from "axios";

const AuthContext = React.createContext();

// Hook para consumir o AuthContext
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading]         = useState(true);

    const [isStepActive, setIsStepActive]   = useState(null);
    const [isCardActive, setIsCardActive]   = useState(null);
    const [addStep, setAddStep]             = useState(false);
    const [mobile, setMobile]               = useState(window.innerWidth);
    
    const [plan, setPlan]                   = useState(null);

    const [servicesFlow, setServicesFlow] = useState(4); // serviços (plano gratuito)
    const [limitFlow, setLimitFlow]           = useState(5); // Etapas (plano gratuito)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const updateLimitFlow = () => {
            if (plan === "gratuito") {
                setLimitFlow(5);
                setServicesFlow(4);
            } else if (plan === "padrao") {
                setLimitFlow(10);
                setServicesFlow(10);
            } else if (plan === "avancado") {
                setLimitFlow(40);
                setServicesFlow(25);
            }
        };

        updateLimitFlow();
    }, [plan]);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth <= 600;
            setMobile(isMobile);

            if (isMobile) {
                setIsStepActive(false);
                setIsCardActive(false);
            } else {
                setIsCardActive(true);
                setIsStepActive(true);
            }
        };

        handleResize();
    
        // Adiciona o evento de redimensionamento
        window.addEventListener("resize", handleResize);
    
        // Remove o evento quando o componente desmonta
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    

    // Memorizando o valor do contexto
    const value = useMemo(
        () => ({
            currentUser,
            mobile,
            loading,
            isStepActive,
            setIsStepActive,
            isCardActive,
            setIsCardActive,
            addStep,
            setAddStep,
            limitFlow,
            plan,
            setPlan,
            setServicesFlow,
            servicesFlow
        }),
        [
            currentUser,
            loading,
            isStepActive,
            isCardActive,
            mobile,
            addStep,
            limitFlow,
            plan,
            servicesFlow
        ]
    );

    return (
        <AuthContext.Provider value={value}>
            {!loading ? children : <Loading />}
        </AuthContext.Provider>
    );
}

// Função para verificar email usando a API Hunter
export const verifyEmailWithHunter = async (email) => {
    try {
        const apiKey = '61ae94223165c36504a975118c974e99cdb9ee74';

        // Remover espaços em branco antes e depois do email
        const trimmedEmail = email.trim();

        // Validação do formato do email
        const isEmailValidFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail);
        if (!isEmailValidFormat) {
            console.log("Formato de e-mail inválido.");
            return false;
        }

        // Fazendo a requisição para a API
        const response = await axios.get(`https://api.hunter.io/v2/email-verifier`, {
            params: {
                email: trimmedEmail,
                api_key: apiKey,
            },
        });

        // Depurando a resposta da API
        const emailData = response.data.data;
        console.log("Resposta da API:", emailData);

        const { status, score } = emailData;

        if (status === 'deliverable') {
            console.log('Email válido e entregável!');
            return true;
        } else if (status === 'accept_all') {
            console.log('Email em domínio que aceita todos os emails!');
            return score > 70; // Definir limite de confiança
        } else {
            console.log('Email inválido ou não entregável!');
            return false;
        }
    } catch (error) {
        console.error('Erro ao verificar o email:', error.response?.data || error.message);
        return false;
    }
};
